<template>
  <div>
    <el-card>
      <h2>找回密码</h2>
      <h3>请输入您需要找回登录密码的账号</h3>
      <div class="tabnav">
        <div
          @click="changer(1)"
          :class="{
            tabitem: true,
            active: activeName == '1' ? true : false,
            disabled: disabled
          }"
        >
          <span
            :class="{ circle: true, active1: activeName == '1' ? true : false }"
            >1</span
          >
          填写信息
        </div>
        <div
          @click="changer(2)"
          :class="{
            tabitem: true,
            active: activeName == '2' ? true : false,
            disabled: disabled1
          }"
        >
          <span
            :class="{ circle: true, active1: activeName == '2' ? true : false }"
            >2</span
          >
          验证信息
        </div>
        <div
          @click="changer(3)"
          :class="{
            tabitem: true,
            active: activeName == '3' ? true : false,
            disabled: disabled2
          }"
        >
          <span
            :class="{ circle: true, active1: activeName == '3' ? true : false }"
            >3</span
          >
          重置密码
        </div>
        <div
          @click="changer(4)"
          :class="{
            tabitem: true,
            active: activeName == '4' ? true : false,
            disabled: disabled3
          }"
        >
          <span
            :class="{ circle: true, active1: activeName == '4' ? true : false }"
            >4</span
          >
          重置成功
        </div>
      </div>
      <!-- 填写账号名称 -->
      <el-form
        v-if="activeName == '1'"
        :model="nameForm"
        :rules="rules"
        ref="nameForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="账号：" prop="name">
          <el-input
            :rules="[
              {
                required: true,
                message: '请输入邮箱地址或者手机号',
                trigger: 'blur'
              }
            ]"
            v-model="nameForm.name"
            placeholder="请输入邮箱/手机号"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('nameForm')"
            >下一步</el-button
          >
        </el-form-item>
      </el-form>
      <!-- 填写账号验证码 -->
      <el-form
        v-if="activeName == '2'"
        :model="verificationForm"
        ref="verificationForm"
        :rules="verificationrule"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item v-if="type == 1">
          <h4>邮箱：{{ email }}</h4>
        </el-form-item>
        <el-form-item v-if="type == 2">
          <h4>手机号：{{ iphone }}</h4>
        </el-form-item>
        <el-form-item label="验证码：" prop="number">
          <el-input
            type="number"
            v-model="verificationForm.number"
            placeholder="请输入验证码"
          >
            <template slot="append">
              <span v-show="show" @click="getCode">获取验证码</span>
              <span v-show="!show" class="count">{{ count }} s</span></template
            ></el-input
          >
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="verificationsubmit('verificationForm')"
            >下一步</el-button
          >
        </el-form-item>
      </el-form>
      <!-- 新密码 -->
      <el-form
        v-if="activeName == '3'"
        :model="passwordForm"
        ref="passwordForm"
        :rules="passwordrule"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="密码：" prop="password">
          <el-input
            v-model="passwordForm.password"
            placeholder="请输入密码"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item label="确认密码：" prop="password1">
          <el-input
            v-model="passwordForm.password1"
            show-password
            placeholder="请再次输入密码"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="passwordsubmit('passwordForm')"
            >下一步</el-button
          >
        </el-form-item>
      </el-form>
      <!-- 成功 -->
      <div class="success" v-if="activeName == '4'">
        <span>
          <el-image
            style="width: 60px; height: 60px;vertical-align:middle;margin-right: 20px;"
            :src="require('@/util/images/index/icon_success.png')"
            fit="contain"
          ></el-image
          >新密码设置成功！</span
        >
      </div>
    </el-card>
  </div>
</template>

<script>
import {
  emssend,
  iphonesend,
  emscheck,
  smscheck,
  resetPwd
} from "../../api/userapi";
export default {
  name: "forget",

  components: {},

  data() {
    return {
      disabled: true,
      disabled1: true,
      disabled2: true,
      disabled3: true,
      timer: null,
      show: true,
      count: 0,
      text: "发送验证码",
      iphone: "",
      email: "",
      type: 1,
      token: "",
      activeName: "1",
      nameForm: { name: "" },
      verificationForm: { number: "" },
      passwordForm: { password: "", password1: "" },
      rules: {
        name: [{ required: true, message: "请填写账号", trigger: "blur" }]
      },
      verificationrule: {
        number: [{ required: true, message: "请填写验证码", trigger: "blur" }]
      },
      passwordrule: {
        password: [{ required: true, message: "请填密码", trigger: "blur" }],
        password1: [{ required: true, message: "请填密码", trigger: "blur" }]
      }
    };
  },

  mounted() {},

  methods: {
    getCode() {
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.show = false;
        this.sendcode();
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.show = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },
    // 发送验证码
    sendcode() {
      var that = this;
      if (that.type === 1) {
        emssend({
          email: that.nameForm.name,
          event: "找回密码"
        }).then(res => {
          if (res.code === 1) {
            that.$message.success(res.msg);
          } else {
            that.$message.error(res.msg);
          }
        });
      } else if (that.type === 2) {
        iphonesend({
          mobile: that.nameForm.name,
          event: "找回密码"
        }).then(res => {
          if (res.code === 1) {
            that.$message.success(res.msg);
          } else {
            that.$message.error(res.msg);
          }
        });
      }
    },
    // 加密邮箱
    getEmail(phone) {
      var str = phone.split("@");
      const str1 =
        str[0].substr(0, str[0].length - 2) + "*****" + str[1].substr(2); // 73856000*****.com
      return str1;
    },
    // 加密手机号
    getPhone(phone) {
      return phone.substring(3, 0) + "****" + phone.substring(7);
    },
    // 发送验证码
    handleClick(tab, event) {},
    changer(index) {
      this.activeName = index;
    },
    // 下一步加提交账号名称
    submitForm(formName) {
      const that = this;
      this.$refs[formName].validate(valid => {
        if (valid) {
          const reg = new RegExp(
            "^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$"
          );
          var mobile1 = /^1[3456789]\d{9}$/;
          if (reg.test(that.nameForm.name)) {
            that.type = 1;
            that.email = that.getEmail(that.nameForm.name);
            that.changer(2);
            this.disabled1 = false;
            this.disabled = false;
          } else if (mobile1.test(that.nameForm.name)) {
            that.type = 2;
            that.iphone = that.getPhone(that.nameForm.name);
            this.disabled1 = false;
            this.disabled = false;
            that.changer(2);
          } else {
            that.$message.error("格式错误请重新提交");
          }
        } else {
          that.$message.error("提交错误请重新提交");
          return false;
        }
      });
    },
    // 下一步加提交验证码
    verificationsubmit(formName) {
      const that = this;
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (that.type === 1) {
            emscheck({
              email: that.nameForm.name,
              event: "找回密码",
              captcha: that.verificationForm.number
            }).then(res => {
              if (res.code === 1) {
                that.$message.success(res.msg);
                that.passwordForm.token = res.token;
                that.passwordForm.email = that.nameForm.name;
                that.changer(3);
                that.disabled = true;
                that.disabled1 = true;
              } else {
                that.$message.error(res.msg);
              }
            });
          } else if (that.type === 2) {
            smscheck({
              mobile: that.nameForm.name,
              event: "找回密码",
              captcha: that.verificationForm.number
            }).then(res => {
              if (res.code === 1) {
                that.$message.success(res.msg);
                that.passwordForm.token = res.token;
                that.passwordForm.email = that.nameForm.name;
                that.changer(3);
                that.disabled = true;
                that.disabled1 = true;
              } else {
                that.$message.error(res.msg);
              }
            });
          }
        } else {
          that.$message.error("提交错误请重新提交");
          return false;
        }
      });
    },
    password() {
      if (this.passwordForm.password === this.passwordForm.password1) {
        return true;
      } else {
        return false;
      }
    },
    // 下一步加提交重置密码
    passwordsubmit(formName) {
      debugger;
      const that = this;
      that.$refs[formName].validate(valid => {
        if (valid) {
          if (!that.password()) {
            return that.$message.error("密码必须一致");
          }
          var from = JSON.stringify(that.passwordForm);
          from = JSON.parse(from);
          delete from.password1;
          resetPwd(from).then(res => {
            if (res.code === 1) {
              that.changer(4);
              // that.$alert(res.msg, {
              //   confirmButtonText: "确定",
              //   callback: action => {
              //     that.$router.push("/login");
              //   }
              // });
            } else {
              that.$message.error(res.msg);
            }
          });
        } else {
          that.$message.error("提交错误请重新提交");
          return false;
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.disabled {
  pointer-events: none;
}
.success {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  span {
    font-size: 24px;
    color: #333333;
  }
}
.tabnav {
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: space-around;
  .tabitem {
    width: 133px;
    height: 60px;
    line-height: 60px;
    font-size: 24px;
    color: #ccc;
  }
  .circle {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #ccc;
    text-align: center;
    line-height: 24px;
    color: #ffffff;
    display: inline-block;
  }
  .active {
    color: #0383e4;
    border-bottom: 1px solid #0383e4;
  }
  .active1 {
    background: #0383e4;
  }
}
.el-form {
  width: 50%;
  margin: 50px auto;
  h4 {
    font-size: 14px;
    color: #333333;
  }
  .el-form-item {
    margin-bottom: 0;
  }
  .el-input {
    margin-bottom: 20px;
  }
  .el-button {
    margin-bottom: 100px;
  }
}
h2 {
  font-size: 24px;
  color: #333333;
  margin-bottom: 15px;
}
h3 {
  font-size: 18px;
  color: #333333;
  margin-bottom: 50px;
}
.el-card {
  width: 1200px;
  margin: 50px auto;
  padding: 50px 75px;
}
</style>
